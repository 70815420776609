<script setup lang="ts">
import { CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import { cloneDeep } from 'lodash-es'
import type { SbHeadlineStoryblok } from '~/types/storyblok'

const { blok } = defineProps<{ blok: SbHeadlineStoryblok }>()

const schema = cloneDeep(RichTextSchema)

const responsive = useResponsive()

// tag
const renderedTag = computed(() => blok.tag?.content?.length ? renderRichText(blok.tag) : null)

// title
const renderedTitle = computed(() => blok.title?.content?.length ? renderRichText(blok.title) : null)
const renderedTitleSize = computed(() => blok.titleSize || 'h1')

// subtitle
const renderedSubtitle = computed(() => renderRichText(blok.subtitle))

// text
const renderedText = computed(() => blok.text?.content?.length
  ? renderRichText(blok.text, {
    schema,
    resolver: (component, blok) => {
      switch (component) {
        case 'SBButtonGroup':
          return `<component is="${component}" :blok='${JSON.stringify(blok)}' />`
        case 'SBImage':
          return `<component is="${component}" :blok='${JSON.stringify(blok)}' />`
        default:
          return `<div class="text-xs px-1.5 py-0.5 bg-red-500 text-white inline-block">Resolver for <span class="underline font-highlighted">${component}</span> not defined</div>`
      }
    },
  })
  : null,
)

const mobileTextAlignClass = computed(() => {
  switch (blok.textAlignMobile) {
    case 'center':
      return 'max-lg:text-center'

    case 'right':
      return 'max-lg:text-right'

    case 'left':
      return 'max-lg:text-left'

    default:
      return 'max-lg:text-left'
  }
})

const mobileTextAlignMarginClass = computed(() => {
  switch (blok.textAlignMobile) {
    case 'center':
      return 'max-lg:mx-auto'

    case 'right':
      return 'max-lg:ml-auto'

    default:
      return ''
  }
})

const textAlignClass = computed(() => {
  switch (blok.textAlign) {
    case 'center':
      return 'lg:text-center'

    case 'right':
      return 'lg:text-right'

    case 'left':
      return 'lg:text-left'

    default:
      return 'lg:text-left'
  }
})

const textAlignMarginClass = computed(() => {
  switch (blok.textAlign) {
    case 'center':
      return 'lg:mx-auto'

    case 'right':
      return 'lg:ml-auto'

    default:
      return ''
  }
})

const limitWidthClass = computed(() => {
  return blok.limitWidth ? 'max-w-[1200px]' : ''
})
</script>

<template>
  <div
    v-editable="blok"
    class="flex max-w-screen-lg flex-col gap-y-5"
    :class="[
      mobileTextAlignClass,
      mobileTextAlignMarginClass,
      textAlignClass,
      limitWidthClass,
      textAlignMarginClass,
    ]"
  >
    <div class="flex flex-col">
      <TypographyStyledText
        v-if="renderedTag"
        class="mb-3"
      >
        <CfgTypography
          class="!font-semibold text-brand md:!font-extrabold"
          :html="renderedTag"
          :size="responsive.mobile ? CfgTypographySizes.md : CfgTypographySizes.subtitle"
          tag-name="h3"
        />
      </TypographyStyledText>

      <TypographyStyledText v-if="renderedTitle || renderedSubtitle">
        <TypographySanitizedHTML
          v-if="renderedTitle"
          :allowed-classes="['text-brand', 'text-white']"
          :allowed-tags="['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span', 'b', 'strong', 'br', 'a']"
          :html="renderedTitle"
        >
          <template #default="{ html }">
            <CfgTypography
              class="text-balance"
              :class="{
                'text-[28px] md:text-[56px]': renderedTitleSize === 'h1-large',
              }"
              :html="html"
              :size="CfgTypographySizes[renderedTitleSize]"
              tag-name="span"
            />
          </template>
        </TypographySanitizedHTML>
      </TypographyStyledText>

      <TypographyStyledText v-if="renderedSubtitle" class="mt-5">
        <TypographySanitizedHTML
          v-if="renderedSubtitle"
          :allowed-tags="['span', 'b', 'strong', 'p', 'a']"
          class="mt-4"
          :html="renderedSubtitle"
        >
          <template #default="{ html }">
            <CfgTypography
              class="text-balance"
              :html="html"
              :size="responsive.mobile ? CfgTypographySizes.md : CfgTypographySizes.subtitle"
              tag-name="span"
            />
          </template>
        </TypographySanitizedHTML>
      </TypographyStyledText>

      <TypographyStyledText
        v-if="renderedText"
        class="mt-3 !max-w-[650px]"
      >
        <TypographySanitizedHTML
          v-if="renderedText"
          :allowed-classes="['text-brand', 'text-white']"
          :allowed-tags="['span', 'b', 'strong', 'p', 'a', 'ul', 'li', 'ol']"
          class="pt-5"
          :html="renderedText"
        >
          <template #default="{ html }">
            <CfgTypography :html="html" :size="CfgTypographySizes.md" />
          </template>
        </TypographySanitizedHTML>
      </TypographyStyledText>
    </div>
  </div>
</template>
